<template>
  <div class="container">
    <div
      role="alert"
      v-bind:class="{ show: errors.length }"
      class="alert fade alert-danger"
    >
      <div class="alert-text" v-for="(error, i) in errors" :key="i">
        {{ error }}
      </div>
    </div>
    <!--begin::Page Layout-->
    <div class="card card-custom">
      <div class="card-header">
        <h3 class="card-title">
          Analyze term
        </h3>
      </div>
      <div class="card-body">
        <form class="form">
          <div class="card-body">
            <div class="form-group">
              <label>Term:</label>
              <input
                type="text"
                name="q"
                v-model="qString"
                class="form-control form-control-solid"
              />
            </div>
            <div class="form-group">
              <label>Schema name:</label>
              <input
                type="text"
                name="s"
                v-model="sString"
                class="form-control form-control-solid"
              />
              <span class="form-text text-muted"
                >Copy name of schema by collection</span
              >
            </div>
            <div class="form-group">
              <collection_select />
            </div>
          </div>
        </form>
      </div>
      <div class="card-footer">
        <a
          href="#"
          class="btn btn-info"
          ref="submit"
          @click.prevent="loadAction"
          >Load</a
        >
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-4 bg-dark text-white">
        <strong>Text Search</strong>
        <pre class="language-json text-white">{{
          this.analyze_result.search
        }}</pre>
      </div>
      <div class="col-4 bg-dark text-white">
        <strong>Phrase Search</strong>
        <pre class="language-json text-white">{{
          this.analyze_result.phrase
        }}</pre>
      </div>
      <div class="col-4 bg-dark text-white">
        <strong>Prefix Search</strong>
        <pre class="language-json text-white">{{
          this.analyze_result.prefix
        }}</pre>
      </div>
    </div>
    <!--end::Page Layout-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters, mapState } from "vuex";
import collection_select from "@/view/pages/collections/collection_select";
import { COMPARE_RESULTS_ANALYZE_MODULE } from "@/core/services/store/compare_results.module";

export default {
  components: { collection_select },
  data() {
    return {
      qString: "",
      sString: ""
    };
  },
  methods: {
    serialize: obj => {
      var str = [];
      for (var p in obj)
        if (obj.hasOwnProperty(p)) {
          str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
      return str.join("&");
    },
    loadAction() {
      this.$store.dispatch(COMPARE_RESULTS_ANALYZE_MODULE, {
        q: this.qString,
        s: this.sString,
        c: this.selectedCollection.id
      });
    },
    reloadData() {}
  },
  watch: {
    changeProject() {
      this.reloadData();
    },
    changeCollection() {
      this.reloadData();
    }
  },
  computed: {
    changeProject() {
      return this.currentProject.token;
    },
    changeCollection() {
      return this.selectedCollection.id;
    },
    ...mapState({
      errors: state => state.compare_results.errors,
      message: state => state.compare_results.message,
      loading: state => state.compare_results.loading,
      analyze_result: state => state.compare_results.analyze
    }),
    ...mapGetters(["selectedCollection", "currentProject"])
  },
  beforeCreate() {},
  mounted() {
    this.reloadData();
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Compare results" }]);
  }
};
</script>
<style>
.slider {
  /* overwrite slider styles */
  width: 200px;
}
</style>
